import React from 'react';
import styled from 'styled-components';


const Highlight = styled.p`
    background: #0070c9;
    display: inline-block;
    padding: 0 5px;
    color: #fff;
    text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
    -webkit-border-radius: 3px 3px 3px 3px;
    -moz-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    font-weight: bold;
    font-size: 12px;
`;


const BlueCard = (props) => {
    const text1 = props.text1
    const text2 = props.text2
    const text3 = props.text3

    return (
        <Highlight>
            {text1}
            {text2 && <> <br /> {text2} </>}
            {text3 && <> <br /> {text3} </>}
        </Highlight>
    )
};

export default BlueCard;
