import React from 'react';
import BlueCard from "./blueCard";
import styled from 'styled-components';
import { useIntl, Link } from 'gatsby-plugin-intl'
// import { pc, sp, tab } from '../media';



const Li = styled.li`
    margin-top: 10px;
`;

const Section = (props) => {
    const policy = props.message;
    const intl = useIntl();


    return (
        <div className="ui text container box">
            <h1 className="bankinvoice-privacy-policy">{policy.title}</h1>
            <br />
            <div>{policy.subTitle} </div>
            {
                policy.articles && policy.articles.map((article, i) => (
                    <article key={i}>
                    <h5 className="title-article">{article.title}</h5>
                    <p >{article.p}</p>
                    {
                        article.ol && 
                        <ol>
                        {
                            article.ol.liList && article.ol.liList.map((liObj, i_j) => (
                            <>
                                {liObj.title && <h5>{liObj.title}</h5>}
                                <Li>
                                {liObj.text}
                                <p className="li_p"> {liObj.p}</p>
                                {liObj.ol && 
                                        <ol>
                                        {liObj.ol.liList && liObj.ol.liList.map(liObj2 => (
                                            <li>
                                            {liObj2.text}
                                            </li>
                                        ))}
                                        </ol>
                                }
                                {
                                    liObj.highlight && 
                                    <BlueCard text1={liObj.highlight.info}  text2={liObj.highlight.phoneNumber} text3={liObj.highlight.corporation}  />
                                }
                                </Li>
                            </>
                        ))}
                        </ol>
                    }
                    </article>
                ))
            }
        <br />
        <p>{policy.enactment}</p>
        <h6>{policy.at}</h6>
        <br />
        <Link to="/">{intl.formatMessage({id: "back"})}</Link>
        </div>
    )
};

export default Section;
